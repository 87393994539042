




















































import Vue, { PropType } from 'vue'

import { Events, UndoStack } from '@/models/undoRedo/undoRedo'

export default Vue.extend({
  name: 'StackViewer',
  props: {
    stack: {
      type: Object as PropType<UndoStack<unknown, any, any>>,
      required: true,
    },
  },
  data() {
    return {
      messages: [] as any[],
      opened: false,
    }
  },
  mounted(): void {
    const listen: (keyof Events)[] = ['commit', 'undo', 'redo']
    listen.forEach((event) => {
      this.stack.on(event, (payload) => {
        this.messages.push({
          payload,
          eventName: event,
        })
      })
    })
  },
  methods: {
    open(): void {
      this.opened = !this.opened
    },
    async exportStack() {
      const myExport = await this.stack.export()
      console.info(myExport)
    },
  },
})
