


















import Vue, { PropType } from 'vue'
import NodeBase from '@/components/jsplumb/node-base.vue'
import { KNodeStack } from '@/pages/admin/models/knode-stack'

export default Vue.extend({
  name: 'ClassDesignerNode',
  components: {
    NodeBase,
  },
  props: {
    node: {
      type: Object as PropType<KNodeStack>,
      required: true,
    },
    isPreview: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      mfClass: [],
    }
  },
  computed: {
    state(): any {
      return this.node.getState()
    },
  },
  mounted() {
    console.log(`ADDED ITEM ${this.node.getState().id}`)
  },
})
