
























import { Component, Vue } from 'vue-property-decorator'
import KInput from '@/components/knitiv/kinput.vue'

@Component({
  inheritAttrs: false,
  components: {
    KInput,
  },
})
export default class SearchBox extends Vue {}
