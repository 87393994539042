









































































































































































import Vue from 'vue'
import { BrowserJsPlumbInstance } from '@jsplumb/browser-ui'
import * as jsPlumb from '@jsplumb/browser-ui'
import { Connection, Endpoint } from '@jsplumb/core'
import { nanoid } from 'nanoid'
import panzoom from 'panzoom'
// import { ConnectParams } from '@jsplumb/core'
import { AutocompleteItem } from '@knitiv/api-client-javascript'
import { Drag, Drop } from 'vue-easy-dnd'
import { Position } from 'vue-router/types/router'
import { Promisable } from 'type-fest'
import connectionTypesFactory from './edit/connectionTypeFactory'
import { KNode, KNodeStack } from '@/pages/admin/models/knode-stack'
import {
  AddLinkPayload,
  AddNodePayload,
  ClassDesignerStack,
  LinkPickerResult,
  SetNodePositionPayload,
} from '@/pages/admin/models/class-designer'
import Page from '@/components/layouts/page.vue'
import NodeComponent from '@/components/admin/class-designer/node.vue'
import MfInput from '@/components/mf/mf.vue'
import StackViewer from '@/components/admin/utils/stack-viewer.vue'
import SearchBox from '@/components/knitiv/searchBox.vue'
import LinkPicker from '@/components/admin/class-designer/link-picker.vue'
import { filterRecord } from '@/utils'
import { Events } from '@/models/undoRedo/undoRedo'
import { WithRefs } from '@/models/vue'

interface BeforeDrop {
  sourceId: string,
  targetId: string,
  scope: any,
  connection: Connection,
  dropEndpoint: Endpoint,
  source: HTMLElement,
  target: HTMLElement
}

interface Data {
  nodeId: string | undefined;
  instance: null | BrowserJsPlumbInstance;
  panzoom: null | any;

  drawer: boolean;
  selectedNode: KNodeStack | null;

  blocSearch: string
  cache: Record<string, KNode>

  zoom: number;

  menu: {
    x: number;
    y: number;
    show: boolean;
  }

  canUndo: boolean;
  canRedo: boolean;
  nodes: KNodeStack[];

  canvasStyle: any;
}

const MAX_ZOOM = 1
const MIN_ZOOM = 0.25

const listen: (keyof Events)[] = ['commit', 'undo', 'redo']

type Refs = {
}

export default (Vue as WithRefs<Refs>).extend({
  name: 'ClassDesigner',
  components: {
    Page,
    NodeComponent,
    SearchBox,
    MfInput,
    Drag,
    Drop,
    StackViewer,
  },
  data(): Data {
    return {
      nodeId: undefined,
      instance: null,
      panzoom: null,

      drawer: true,
      selectedNode: null,

      blocSearch: '',
      cache: {},

      zoom: 1,

      menu: {
        x: 0,
        y: 0,
        show: false,
      },

      canUndo: false,
      canRedo: false,
      nodes: [],
      canvasStyle: {
        height: '600px',
        width: '600px',
        minHeight: '600px',
        minWidth: '600px',
        padding: '50px',
      },
    }
  },
  computed: {
    name: {
      get(): string {
        return this.$accessor.classdesigner.name
      },
      set(name: string) {
        this.$accessor.classdesigner.SET_NAME(name)
      },
    },
    stack(): ClassDesignerStack {
      return this.$accessor.classdesigner.stack
    },
    CANVAS_SIZE(): number {
      return this.$accessor.importfile.canvasSize
    },
    filteredItems(): Data['cache'] {
      return filterRecord(this.cache, (item) => item.name.toLowerCase().includes(this.blocSearch.toLowerCase()))
    },
  },
  beforeDestroy() {
    listen.forEach((eventName) => {
      this.stack.removeListener(eventName)
    })

    this.$accessor.classdesigner.RESET()

    // TODO no ondes found
    document.querySelectorAll('.graph-node').forEach((element) => {
      console.log('element', element)
      element.remove()
    })
    if (this.instance) {
      this.instance.destroy()
    }
  },
  async mounted(): Promise<void> {
    const canvas = this.$refs.canvas as HTMLElement

    this.panzoom = panzoom(canvas, {
      zoomSpeed: 1,
      smoothScroll: false,
      zoomDoubleClickSpeed: 1,
      maxZoom: MAX_ZOOM,
      minZoom: MIN_ZOOM,

      beforeMouseDown(e) {
        const shouldIgnore = (e.target as Element).classList.contains('panzoom-exclude')

        if (shouldIgnore) {
          e.preventDefault()
        }

        return shouldIgnore
      },
    })

    this.panzoom.on('zoom', () => {
      if (this.panzoom && this.instance) {
        const transform = this.panzoom.getTransform()
        this.zoom = transform.scale
        this.instance.setZoom(transform.scale)
      }
    })

    this.instance = jsPlumb.newInstance({
      endpoint: {
        type: 'Dot',
        options: { radius: 8, cssClass: 'dot-endpoint' },
      },
      connectionOverlays: [
        {
          type: 'Arrow',
          options: {
            location: 1,
            id: 'arrow',
            length: 20,
            foldback: 1,
          },
        },
      ],
      container: canvas,
      dragOptions: {
        grid: [20, 20],
      },
      connector: {
        type: 'Bezier',
        options: {
          // @ts-ignore
          curviness: 60,
        },
      },
      // paintStyle: { stroke: 'red', strokeWidth: 2 },
      endpointStyle: { fill: 'black', outlineStroke: 'black', outlineWidth: 1 },
      // @ts-ignore
      connectorPaintStyle: { stroke: 'blue', strokeWidth: 4 },
    })

    await this.$nextTick()

    listen.forEach((eventName) => {
      this.stack.on(eventName, async (event) => {
        this.nodes = this.stack.getState().nodes
        this.canRedo = this.stack.canRedo()
        this.canUndo = this.stack.canUndo()

        if (event.name === 'ADD_NODE') {
          if (eventName === 'undo') {
            const payload = event.payload as AddNodePayload

            await this.removeNodeUI(payload.node.getState().id)
          } else {
            const payload = event.payload as AddNodePayload

            console.log('payload.node', payload.node)
            await this.addNodeUI(payload.node)
          }
        }

        if (event.name === 'SET_NODE_POSITION') {
          const payload = event.payload as SetNodePositionPayload

          await this.uiSetNodePosition(
            payload.id,
            payload.position,
          )
        }

        if (event.name === 'ADD_LINK') {
          if (eventName === 'undo') {
            const payload = event.payload as AddLinkPayload

            this.removeLinkUI(payload)
          } else {
            const payload = event.payload as AddLinkPayload

            this.addLinkUI(payload)
          }
        }

        if (event.name === 'EDIT_LINK' && this.instance) {
          const payload = event.payload as AddLinkPayload

          const source = document.getElementById(payload.source)
          const target = document.getElementById(payload.target)

          if (source && target) {
            const connections = this.instance.getConnections({
              source,
              target,
            })
            if (Array.isArray(connections)) {
              console.log('connections', connections)
              const connection = connections.find((con) => {
                const params = con.parameters
                if (params.id === payload.id) {
                  return true
                }
                return false
              })
              if (connection) {
                this.instance.deleteConnection(connection)
              }
            }

            this.addLinkUI(payload)
          }
        }
      })
    })

    this.instance.bind('connectionDrag', (info: BeforeDrop) => {
      console.log('info', info)
    })
    this.instance.bind('beforeDrag', (info: BeforeDrop) => {
      console.log('info', info)
    })
    this.instance.bind('click', async (connection: Connection) => {
      console.log('a', connection)

      const answer = await this.$dialog.open<LinkPickerResult>(LinkPicker, {
        maxWidth: 700,
        component: {
          id: connection.parameters.id,
        },
      })

      console.log('anwser', answer)

      if (answer !== 'close') {
        const addLinkPayload: AddLinkPayload = {
          source: connection.source.id,
          target: connection.target.id,
          type: answer.type,
          id: answer.id,
          cardinality: answer.cardinality,
        }

        if (answer.linker) {
          addLinkPayload.linker = answer.linker.kid

          const node = await KNode.fromKid(answer.linker.kid)
          this.insertClassInCache(node)
        }

        this.stack.editLink(addLinkPayload)
      }
      this.repaint()
    })
    this.instance.bind('connection:abort', (info, event) => {
      console.log('event', event)
      this.menu.x = event.clientX
      this.menu.y = event.clientY
      this.menu.show = true
    })

    this.instance.bind('connection:detach', (infos: BeforeDrop, originalEvent: Event | undefined) => {
      if (!originalEvent) {
        return
      }

      console.log('infos', infos)
      this.stack.removeLink(infos.connection.data.id)
    })

    this.instance.bind('connection', async (infos: BeforeDrop, originalEvent: Event | undefined) => {
      console.log('infos', infos)

      if (originalEvent === undefined) {
        return
      }

      const con = infos.connection

      const answer = await this.$dialog.open<LinkPickerResult>(LinkPicker, {
        maxWidth: 700,
        component: {
          // infos
        },
      })

      console.log('anwser', answer)

      if (answer !== 'close') {
        const addLinkPayload: AddLinkPayload = {
          source: con.source.id,
          target: con.target.id,
          type: answer.type,
          id: nanoid(),
          cardinality: answer.cardinality,
        }

        if (answer.linker) {
          addLinkPayload.linker = answer.linker.kid

          const node = await KNode.fromKid(answer.linker.kid)
          this.insertClassInCache(node)
        }

        this.stack.addLink(addLinkPayload)
      }
      console.log('con', con)
      this.instance?.deleteConnection(con)
      this.repaint()
    })

    const _nodes = [
      'K_NODE;PERSON',
      'K_NODE;FIRSTNAME',
      'K_NODE;LASTNAME',
      'K_NODE;DATE',
      'K_NODE;BEGIN',
      'K_NODE;END',
    ]

    console.log('this.nodes', this.nodes)

    const promises = []
    for (const nodeKid of _nodes) {
      promises.push(async () => {
        const node = await KNode.fromKid(nodeKid)
        this.insertClassInCache(node)
      })
    }

    await Promise.all(promises.map((x) => x()))

    // Load after al setup is done
    if (this.$route.params.id) {
      this.nodeId = this.$route.params.id
      await this.$accessor.classdesigner.load(this.nodeId)
      this.repaint()
    }
  },
  methods: {
    setCanvasStyle(): any {
      console.log('oh oh oh dragging !')
      const padding = 50

      const maxX = Math.max.apply(Math, this.nodes.map((o) => {
        const el = document.getElementById(o.getState().id)
        if (el) {
          return el.clientLeft
        }
        return 0
      }))

      const maxY = Math.max.apply(Math, this.nodes.map((o) => {
        const el = document.getElementById(o.getState().id)
        if (el) {
          return el.clientTop
        }
        return 0
      }))

      console.log('maxX', maxX)
      console.log('maxY', maxY)

      this.canvasStyle.height = `${maxY + padding}px` // `${this.CANVAS_SIZE}px`,
      this.canvasStyle.width = `${maxX + padding}px` // `${this.CANVAS_SIZE}px`
    },
    async save() {
      try {
        const shouldReplace = this.$accessor.classdesigner.target === ''

        const result = await this.$accessor.classdesigner.save()
        console.log('result', result)

        if (shouldReplace) {
          this.$router.replace(`/admin/class-designer/edit/${result}`)
        }
        this.$toast.success('Schéma enregistré avec succes')
      } catch (e) {
        this.$toast.error(e.message)
      }
    },
    repaint() {
      const canvas = this.$refs.canvas as Element

      this.instance?.revalidate(canvas)
      this.instance?.repaintEverything()
    },
    onCanvasDrop(item: {
      data: AutocompleteItem,
      native: MouseEvent,
      position: Position
    }) {
      const {
        data,
        native,
      } = item

      if (native.target) {
        // @ts-ignore
        const rect = native.target.getBoundingClientRect()
        const x = native.clientX - rect.left // x position within the element.
        const y = native.clientY - rect.top // y position within the element.
        console.log(`Left? : ${x} ; Top? : ${y}.`)

        const pos = {
          x,
          y,
        }

        this.addClass(data, pos)
      }
    },
    onQuickAddNode(item: AutocompleteItem) {
      this.menu.show = false

      this.addClass(item, {
        x: 0,
        y: 0,
      })
    },
    async onAddClass(item: AutocompleteItem) {
      const node = await KNode.fromKid(item.kid)
      console.log('node', node)
      this.insertClassInCache(node)
    },

    insertClassInCache(item: KNode) {
      this.$set(this.cache, item.kid, item)
    },

    async removeNodeUI(id: string) {
      // Remove jsplumb links
      const element = document.getElementById(id)
      console.log('element', element)
      if (element) {
        this.instance?.deleteConnectionsForElement(element)
      }

      await this.$nextTick()
    },
    /**
     * @async
     */
    batch <T>(fn: () => Promisable<T>): Promisable<T> {
      return new Promise((resolve, reject) => {
        this.instance?.batch(async () => {
          try {
            const result = await fn()
            return resolve(result)
          } catch (error) {
            return reject(error)
          }
        })
      })
    },
    async uiSetNodePosition(nodeId: string, position: Position) {
      await this.$nextTick()
      const el = document.getElementById(nodeId)
      console.log('nodeId', nodeId)
      console.log('el', el)
      if (el) {
        el.style.left = `${position.x - (el.offsetWidth / 2)}px`
        el.style.top = `${position.y - (el.offsetHeight / 2)}px`
      }
    },
    async addNodeUI(node: KNodeStack) {
      await this.$nextTick()

      const el = document.getElementById(node.getState().id)
      await this.batch(() => {
        if (el) {
          this.instance?.makeSource(el, {
            // @ts-ignore
            detachable: true,
            anchor: 'Continuous',
            paintStyle: {
              stroke: 'green',
            },

            filter: '.graph-exclude',
            filterExclude: true,
          })

          this.instance?.makeTarget(el, {
            allowLoopback: false,
            anchor: 'Continuous',
            // @ts-ignore
            detachable: true,
          })
        } else {
          console.log('element not found')
        }
      })

      await this.$nextTick()
    },
    async autocompleteItemToKNodeStack(item: AutocompleteItem, id: string): Promise<KNodeStack> {
      const node = await KNodeStack.fromKid(item.kid)
      node.getState().setId(id)
      return node
    },
    fakeAutocompleteItemToKNodeStack(item: KNode) {
      const node = KNodeStack.fromKNode(item)
      return node
    },
    async addClass(item: AutocompleteItem, position: Position) {
      const id = nanoid()
      const node = await this.autocompleteItemToKNodeStack(item, id)

      await this.stack.addNodeToCanvas({
        node, id,
      })

      console.log('this.stack', this.stack)

      await this.stack.setNodePosition({
        id,
        position,
      })
    },

    addLinkUI(payload: AddLinkPayload) {
      const source = document.getElementById(payload.source)
      const target = document.getElementById(payload.target)

      if (source && target && this.instance) {
        // const sourceNode = this.nodes.find(node => node.store.state.id === payload.source)
        const targetNode = this.stack.getState().nodes.find((node) => node.getState().id === payload.target)

        const makeTitle = (linker: AddLinkPayload['linker'], target?: KNodeStack) => {
          let title = ''
          if (target?.getState().name) {
            title += target.getState().name
          }
          if (linker) {
            const linkerNode = this.cache[linker]
            console.log('linkerNode', linkerNode)
            if (linkerNode) {
              title += ` :: ${linkerNode.name}`
            }
          }

          return title
        }

        const title = makeTitle(payload.linker, targetNode)

        const factory = connectionTypesFactory[payload.type] ?? connectionTypesFactory.DEFAULT

        this.instance.connect({
          source,
          target,
          anchor: 'Continuous',
        }, factory({
          from: payload.cardinality.from.toString(),
          to: payload.cardinality.to.toString(),
          id: payload.id,
          title,
        }))
      }
    },
    removeLinkUI(connection: AddLinkPayload) {
      const connections = this.instance?.getConnections({
        source: document.getElementById(connection.source) ?? undefined,
        target: document.getElementById(connection.target) ?? undefined,
      })

      // TODO doesn't work
      if (Array.isArray(connections)) {
        const connection = connections?.find((c) => c.id)
        if (connection) {
          this.instance?.deleteConnection(connection)
        }
      }
    },
  },
})
