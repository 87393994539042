var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Page',{attrs:{"classes":"cd-edit-page"},scopedSlots:_vm._u([{key:"drawer",fn:function(){return [_c('v-navigation-drawer',{staticClass:"panzoom-exclude",attrs:{"width":"350","permanent":"","clipped":"","app":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-container',{staticClass:"sidebar-container"},[_c('v-text-field',{staticClass:"no-flex-grow mb-6",attrs:{"outlined":"","dense":"","hide-details":"","name":"name","label":"Nom"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('MfInput',{staticClass:"mf",attrs:{"card-from":1,"card-to":1,"order":['CLASS'],"placeholder":"Choisissez une classe","reset-on-selection":true},on:{"selection":_vm.onAddClass}}),_c('SearchBox',{attrs:{"placeholder":"Recherche"},model:{value:(_vm.blocSearch),callback:function ($$v) {_vm.blocSearch=$$v},expression:"blocSearch"}}),_c('v-list',{staticClass:"cache-list"},_vm._l((_vm.filteredItems),function(component,key){return _c('Drag',{key:key,attrs:{"go-back":"","data":component},scopedSlots:_vm._u([{key:"drag-image",fn:function(){return [_c('NodeComponent',{staticClass:"dnd-placeholder",attrs:{"is-preview":true,"node":_vm.fakeAutocompleteItemToKNodeStack(component)}})]},proxy:true}],null,true)},[_c('v-list-item',{staticStyle:{"cursor":"grab !important"},on:{"click":function () {}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-"+_vm._s(component.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('b',[_vm._v(_vm._s(component.name))]),_vm._v(" "),_c('span',{staticClass:"grey--text lighten-2"},[_vm._v("("+_vm._s(component.kid)+")")])])],1)],1)],1)}),1)],1)],1)]},proxy:true}])},[_c('v-app-bar',{attrs:{"flat":"","color":"grey lighten-1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","disabled":!_vm.canUndo},on:{"click":function($event){return _vm.stack.undo()}}},on),[_c('v-icon',[_vm._v("mdi-undo")])],1)]}}])},[_c('span',[_vm._v("Annuler")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","disabled":!_vm.canRedo},on:{"click":function($event){return _vm.stack.redo()}}},on),[_c('v-icon',[_vm._v("mdi-redo")])],1)]}}])},[_c('span',[_vm._v("Rejouer")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-database-sync")])],1)]}}])},[_c('span',[_vm._v("Synchroniser depuis la mémoire")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.save}},on),[_c('v-icon',[_vm._v("mdi-content-save")])],1)]}}])},[_c('span',[_vm._v("Enregistrer")])])],1),_c('Drop',{staticClass:"objects-wrapper",on:{"drop":function($event){return _vm.onCanvasDrop($event)}}},[_c('div',{ref:"canvas",style:(_vm.canvasStyle),attrs:{"id":"canvas"}},[_vm._l((_vm.nodes),function(node,key){return _c('NodeComponent',{key:key,staticClass:"graph-node",attrs:{"node":node}})}),_c('div',{staticClass:"popover"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"position-x":_vm.menu.x,"position-y":_vm.menu.y,"absolute":""},model:{value:(_vm.menu.show),callback:function ($$v) {_vm.$set(_vm.menu, "show", $$v)},expression:"menu.show"}},[_c('v-card',[_c('MfInput',{staticClass:"mf",attrs:{"card-from":1,"create":"","card-to":1,"order":['CLASS'],"placeholder":"Choisissez une classe"},on:{"selection":_vm.onQuickAddNode}})],1)],1)],1)],2)]),_c('stack-viewer',{attrs:{"position":"top-right","stack":_vm.stack}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }