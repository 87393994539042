import { ConnectParams } from '@jsplumb/core'
import { KRelationV4 } from '@knitiv/api-client-javascript'

export interface createFnParams {
  from: string;
  to: string;
  id: string;
  title?: string;
}

export type createFn = (param: createFnParams) => ConnectParams<Element>

export function createBase(param: createFnParams) {
  console.log('param', param)
  return {
    paintStyle: { stroke: 'black', strokeWidth: 3 },
    parameters: param,
  }
}

export function createDefinedBy(param: createFnParams) {
  console.log('param', param)
  return {
    paintStyle: { stroke: 'blue', strokeWidth: 3 },
    overlays: [
      {
        type: 'Label',
        options: {
          label: `défini par ${param.title ?? ''}`,
          location: [0.5],
          cssClass: 'white-bg elevation-3 panzoom-exclude non-selectable',
        },
      },
      {
        type: 'Label',
        options: {
          label: `${param.from}`,
          location: [0.15],
          cssClass: 'white-bg elevation-3 panzoom-exclude non-selectable',
        },
      },
      {
        type: 'Label',
        options: {
          label: `${param.to}`,
          location: [0.85],
          cssClass: 'white-bg elevation-3 panzoom-exclude non-selectable',
        },
      },
    ],
    parameters: param,
  }
}

export function createComposedof(param: createFnParams) {
  return {
    paintStyle: { stroke: 'red', strokeWidth: 3 },
    overlays: [
      {
        type: 'Label',
        options: {
          label: 'composé de',
          location: 0.5,
          cssClass: 'white-bg elevation-3 panzoom-exclude non-selectable',
        },
      },
      {
        type: 'Label',
        options: {
          label: `${param.from}`,
          location: 0.15,
          cssClass: 'white-bg elevation-3 panzoom-exclude non-selectable',
        },
      },
      {
        type: 'Label',
        options: {
          label: `${param.to}`,
          location: 0.85,
          cssClass: 'white-bg elevation-3 panzoom-exclude non-selectable',
        },
      },
    ],
    parameters: param,
  }
}

export default {
  'K_NODE;DEFINED_BY': createDefinedBy,
  'K_NODE;COMPOSED_OF': createComposedof,
  'K_NODE;ELEMENT_OF': createComposedof,
  DEFAULT: createBase,
} as Record<KRelationV4 | 'DEFAULT', createFn>
