























































import { AutocompleteItem, KRelationV4 } from '@knitiv/api-client-javascript'
import { nanoid } from 'nanoid'
import Vue, { PropType } from 'vue'
import CardinalityChooser from '@/components/cardinality-chooser.vue'
import MfInput from '@/components/mf/mf.vue'
import { Cardinality, LinkPickerResult } from '@/pages/admin/models/class-designer'
import { KNode } from '@/pages/admin/models/knode-stack'

interface Data {
  relationModel: AutocompleteItem[];
  linkerModel: AutocompleteItem[];
  cardinality: Cardinality
}

export default Vue.extend({
  components: {
    MfInput,
    CardinalityChooser,
  },
  props: {
    resolve: {
      type: Function as PropType<(arg: any) => any>,
      required: true,
    },
    reject: {
      type: Function as PropType<(arg: any) => any>,
      required: true,
    },
    id: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data(): Data {
    return {
      relationModel: [],
      linkerModel: [],
      cardinality: {
        from: 0,
        to: 1,
      },
    }
  },
  async mounted() {
    if (!this.id) {
      return
    }

    const { stack } = this.$accessor.classdesigner
    console.log('stack.store.state', stack.getState())

    const link = stack.getState().links.find((link) => link.id === this.id)

    if (!link) {
      throw new Error('Link not found!')
    }

    this.cardinality = link.cardinality

    const relationModel = await KNode.fromKid(link.type)
    this.relationModel = [relationModel.toAutocompleteItem()]

    if (link.linker) {
      const linkerModel = await KNode.fromKid(link.linker)
      this.linkerModel = [linkerModel.toAutocompleteItem()]
    }
  },
  methods: {
    makeResult(): LinkPickerResult {
      return {
        linker: this.linkerModel[0],
        type: this.relationModel[0].kid as KRelationV4,
        cardinality: this.cardinality,
        id: this.id ?? nanoid(),
      }
    },
  },
})
