


































import { SharedConstants } from '@knitiv/api-client-javascript'
import Vue from 'vue'
import { InjectAPI } from '@/utils/api'

interface Data {
  left: number;
  right: number;
  rules: Record<string, any>;
  infinity: boolean;
}

export default Vue.extend({
  mixins: [InjectAPI],
  props: {
    min: {
      required: false,
      default: 0,
      type: Number,
      validator(value: number): boolean {
        return value >= 0
      },
    },
    max: {
      required: false,
      // @ts-ignore
      default: SharedConstants.N_MAX_CARDINALITY,
      type: Number,
      validator(value: number): boolean {
        return value >= 1
      },
    },
  },
  data(): Data {
    return {
      left: 0,
      right: 1,

      rules: {
        left: (value: string) => {
          const nb = parseInt(value, 10)
          return (nb >= this.min && nb < this.max) || `La valeur doit être comprise entre ${this.min} et ${this.max - 1}`
        },
        right: (value: string) => {
          const nbRight = parseInt(value, 10)
          // @ts-ignore
          const nbLeft = parseInt(this.left, 10)
          return (nbRight > this.min && nbRight <= this.max && nbRight > nbLeft) || (nbLeft + 1 === this.max ? `La valeur doit être égale a ${this.max}` : `La valeur doit être comprise entre ${nbLeft + 1} et ${this.max}`)
        },
      },

      infinity: false,
    }
  },
  computed: {
    canSetInfinity(): boolean {
      return this.max >= SharedConstants.N_MAX_CARDINALITY
    },
  },
  methods: {
    toggleInfinity(): void {
      if (this.infinity) {
        this.right = 1
        this.infinity = false
      } else {
        this.right = SharedConstants.N_MAX_CARDINALITY
        this.infinity = true
      }
    },
  },
})

